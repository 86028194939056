import React from 'react'
import ReactGA from 'react-ga'

import Button from '../button'

import './form.scss'
import { CONTACT_FORM } from '../../config/analytics-events'
import { encodeForm } from './utils'

const emptyFields = {
  inputName: '',
  inputEmail: '',
  inputPhone: '',
  inputMessage: '',
}

class ContactForm extends React.Component {
  constructor() {
    super()
    this.state = {
      ...emptyFields,
      contactMessage: null,
      sending: false,
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  resetForm = () => {
    this.setState(emptyFields)
  }

  submitForm = (e) => {
    const _this = this
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
      return false
    }
    const {
      inputName, inputEmail, inputMessage, inputPhone,
    } = this.state
    e.preventDefault()
    this.setState({ sending: true })
    fetch('/php/contact-form.php', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: encodeForm({
        emailTitle: 'Contact Message - HygieNZ website',
        inputName,
        inputPhone,
        inputEmail,
        inputMessage,
      }),
    })
      .then((res) => {
        this.setState({ sending: false })
        if (res.ok) {
          ReactGA.event({
            category: CONTACT_FORM.category,
            action: CONTACT_FORM.successSubmitAction,
          })
          return res.json()
        }
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        return { error: 'Error sending form, please try again later.' }
      })
      .then((data) => {
        if (data.error || !data.success) {
          _this.setState({ contactMessage: { success: false, message: data.messageEnglish } })
        } else {
          _this.resetForm()
          _this.setState({ contactMessage: { success: true, message: data.messageEnglish } })
        }
      })
      .catch((error) => {
        console.error(error)
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        this.setState({ sending: false })
      })
  }

  render() {
    const {
      contactMessage, inputName, inputEmail, inputPhone, inputMessage, sending,
    } = this.state
    return (
      <div className='contact-form'>
        <form id='contact_form' name='contactform' data-toggle='validator' onSubmit={this.submitForm}>
          <div className='form-group'>
            <input
              value={inputName}
              onChange={this.handleChange}
              name='inputName'
              min='2'
              id='inputName'
              placeholder='Name'
              type='text'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputEmail}
              onChange={this.handleChange}
              name='inputEmail'
              id='inputEmail'
              placeholder='Email'
              type='email'
              required
            />
          </div>

          <div className='form-group'>
            <input
              value={inputPhone}
              onChange={this.handleChange}
              name='inputPhone'
              id='inputPhone'
              placeholder='Phone'
              type='text'
            />
          </div>

          <div className='form-group'>
            <textarea
              value={inputMessage}
              onChange={this.handleChange}
              id='inputMessage'
              name='inputMessage'
              placeholder='Message'
              className='form-control'
              type='text'
              rows='6'
              required
            />
          </div>

          <div className='form-group'>
            <Button isButton type='submit' disabled={sending}>
              {sending ? 'Sending ...' : 'Send'}
            </Button>
          </div>
          <div className='contact-message-container'>
            {contactMessage && contactMessage.success
              && <p className='contact-message-success'>{contactMessage.message}</p>
            }
            {contactMessage && !contactMessage.success
              && <p className='contact-message-error'>{contactMessage.message}</p>
            }
          </div>
        </form>
      </div>)
  }
}

export default ContactForm
