import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'

import Slider from './index'
import './SliderSection.scss'

const SliderSection = ({ items, title, small }) => {
  if (!items) { return null }

  const getSlide = (image) => (
    <div className='carousel-slide'>
      <GatsbyImage fluid={image.fluid} alt={image.alt} />
    </div>
  )

  return (
    <div className={`${small ? 'small-img-slider-wrapper' : 'normal-img-slider-wrapper'}`}>
      {title && <h2>{title}</h2>}
      <Slider
        slidesToShow={{ mobile: 2, tablet: small ? 4 : 3, desktop: small ? 5 : 4 }}
        showArrowsOnDesktop={false}
        showArrowsOnMobile={false}
        autoplay
        showDots={false}
      >
        {items.map((image) => getSlide(image))}
      </Slider>
    </div>
  )
}

SliderSection.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
  small: PropTypes.bool,
}

export default SliderSection
