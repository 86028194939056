import React from 'react'

import ServiceItem from './serviceItem'
import './services.scss'

const ServicesSection = () => (
  <div id='servicesSection' className='page-section services-container-section'>
    <h2>Residential and Commercial Services</h2>
    <p>At Hygienz, we are dedicated to meeting all your cleaning needs, whether for your home, office, or more.</p>
    <p>
      Our unmatched cleaning services cater to private homes as well as small, medium, and large businesses.
      With a team of experienced leaders and cleaning experts, we ensure that your home or business is spotless,
      sanitized, and filled with a fresh, clean scent.
    </p>
    <p>
      We offer customized packages tailored to each client, home, and office, always at affordable prices.
      Our commitment to exceptional customer service and satisfaction sets us apart in the industry.
      Let us help you keep your spaces clean and organized!
    </p>
    <div className='services-grid'>
      <ServiceItem
        link='move-in-out-tenancy-cleaning'
        imgSrc='/images/services/move-in-out.jpg'
        title='Move in/out'
        description='At Hygienz Limited, we offer a specialized cleaning service for moving, ensuring
        that your new home is spotless or that the place you are leaving is in perfect condition.
        Our team thoroughly cleans every corner, using quality, eco-friendly products and efficient techniques.
        Make your move easier with our professional assistance.'
      />
      <ServiceItem
        link='commercial-cleaning'
        imgSrc='/images/services/commercial-cleaning.jpg'
        title='Commercial Cleaning'
        description='At Hygienz Limited, we offer a specialized cleaning service for offices, businesses,
        and construction projects, ensuring a clean and professional environment.
        Our highly trained team uses eco-friendly products and efficient techniques
        to eliminate dirt, dust, and allergens, ensuring a healthy space for your employees and clients.'
      />
      <ServiceItem
        link='carpet-cleaning'
        imgSrc='/images/services/carpet-cleaning.jpg'
        title='Carpet cleaning'
        description='At Hygienz Limited, we offer a professional carpet cleaning service that revitalizes your
        home and improves air quality. Our team uses advanced technology and eco-friendly products
        to safely and effectively remove dirt, stains, and allergens.'
      />
      <ServiceItem
        link='window-cleaning'
        imgSrc='/images/services/windows-cleaning.jpg'
        title='Windows cleaning'
        description='Leave your windows spotless and bright with our professional cleaning service.
        Forget about stains and dust; enjoy a clear and shiny view that will enhance the atmosphere
        of your home or office. Contact us today and transform your spaces with always sparkling windows!'
      />
      <ServiceItem
        link='medical-center-cleaning'
        imgSrc='/images/services/medical-cleaning.jpg'
        title='Medical Centers Cleaning'
        description='Ensure a spotless and completely germfree medical center with our professional cleaning service.
        We take care of every detail, eliminating bacteria and ensuring a safe and healthy environment
        for both patients and staff. We provide deep hygiene that reinforces trust and peace of mind in your space.'
      />
      <ServiceItem
        link='office-cleaning'
        imgSrc='/images/services/cleaning-office.jpg'
        title='Cleaning Office'
        description='Keep your office spotless and productive with our professional cleaning service.
        We eliminate dust, dirt, and germs to create a clean and healthy environment that promotes the
        well-being and efficiency of your team. Every corner, from desks to common areas, will shine.'
      />
    </div>
  </div>
)

export default ServicesSection
