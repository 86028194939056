import React from 'react'
import PropTypes from 'prop-types'

import './flipCard.scss'

const FlipCard = ({
  frontContent,
  backContent,
  icon,
}) => (
  <div className='flip-card'>
    <div className='flip-card__inner'>
      <div className='flip-card__face flip-card__front'>
        {icon}
        <p className='flip-card__content'>{frontContent}</p>
        <div className='show-in-mobile'>{backContent}</div>
      </div>
      <div className='flip-card__face flip-card__back'>
        {icon}
        <p className='flip-card__content'>{backContent}</p>
      </div>
    </div>
  </div>
)

FlipCard.propTypes = {
  frontContent: PropTypes.element.isRequired,
  backContent: PropTypes.element.isRequired,
  icon: PropTypes.element.isRequired,
}

export default FlipCard
