import React from 'react'
import PropTypes from 'prop-types'
import 'animate.css/animate.min.css'

import FlipCard from '../cards/FlipCard'
import './featureItem.scss'

const FeatureItem = ({
  imgSrc, imgAlt, title, children,
}) => (
  <div className='feature-item'>
    <FlipCard
      icon={<img className='feature-item-image' src={imgSrc} alt={imgAlt} />}
      frontContent={
        <>
          <h3 className='feature-item-title'>{title}</h3>
        </>
      }
      backContent={
        <>
          <p>{children}</p>
        </>
      }
    />
  </div>
)

FeatureItem.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

export default FeatureItem
