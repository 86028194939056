import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

import ArrowIcon from '../../../static/images/arrow.svg'
import './slider.scss'

const Arrow = ({
  next, className, controlsColor, ...rest
}) => (
  <ArrowIcon {...rest} className={`${className} ${next ? 'next-arrow' : 'prev-arrow'}`} />
)
Arrow.propTypes = {
  next: PropTypes.bool,
}

const CustomSlider = ({
  children, controlsColor, slidesToShow, showArrowsOnDesktop, showArrowsOnMobile, autoplay, showDots,
}) => (
  <Slider
    className={`${controlsColor}-controls-color`}
    dots={showDots}
    infinite
    slidesToShow={slidesToShow.desktop}
    slidesToScroll={1}
    autoplaySpeed={3000}
    autoplay={autoplay}
    swipe
    centerPadding={0}
    draggable
    adaptiveHeight={false}
    variableWidth={false}
    nextArrow={<Arrow next controlsColor={controlsColor} />}
    prevArrow={<Arrow controlsColor={controlsColor} />}
    arrows={showArrowsOnDesktop}
    responsive={[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow.desktop,
          slidesToScroll: 1,
          arrows: showArrowsOnDesktop,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: slidesToShow.tablet,
        },
      },
      {
        breakpoint: 735,
        settings: {
          slidesToShow: slidesToShow.mobile,
          arrows: showArrowsOnMobile,
        },
      },
    ]}
  >
    {children}
  </Slider>
)

CustomSlider.defaultProps = {
  slidesToShow: { mobile: 1, tablet: 2, desktop: 3 },
  controlsColor: 'primary',
  autoplay: false,
  showDots: true,
}

CustomSlider.propTypes = {
  children: PropTypes.array.isRequired,
  slidesToShow: PropTypes.object,
  controlsColor: PropTypes.oneOf(['primary', 'secondary']),
  showArrowsOnMobile: PropTypes.bool.isRequired,
  showArrowsOnDesktop: PropTypes.bool.isRequired,
  autoplay: PropTypes.bool,
  showDots: PropTypes.bool,
}

export default CustomSlider
