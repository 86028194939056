import React from 'react'

import Button from '../button'

import './banner.scss'

const FreeQuoteBanner = () => (
  <div className='banner-container'>
    <div className='banner-content'>
      <div className='banner-text'>
        Request a quote for:
        <div className='animated-words'>
          <div>Commercial Cleaning</div>
          <div>Medical Center Cleaning</div>
          <div>Window Cleaning</div>
          <div>Move In/Out Cleaning</div>
          <div>Carpet Cleaning</div>
        </div>
      </div>
      <Button secondary to='/free-quote'>Get a FREE quote now</Button>
    </div>
  </div>
)

export default FreeQuoteBanner
