import React from 'react'
import PropTypes from 'prop-types'
import 'animate.css/animate.min.css'
import ScrollAnimation from 'react-animate-on-scroll'

import './services.scss'
import Button from '../button'

const ServiceItem = ({
  title, imgSrc, link, description,
}) => (
  <div className='service-item'>
    <ScrollAnimation animateIn='flipInX' animateOnce>
      <div className='service-content'>
        <img src={imgSrc} alt={title} />
        <div><b>{title}</b></div>
        <div className='small-description'>{description}</div>
        <Button isButton={false} secondary to={link}>Read More</Button>
      </div>
    </ScrollAnimation>
  </div>
)

ServiceItem.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
}

export default ServiceItem
