import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Parallax } from 'react-parallax'

import FeatureItem from './featureItem'
import './features.scss'

const Feature = () => (
  <Parallax
    blur={0}
    bgImage='/images/features/features-back.png'
    bgImageAlt='Our features'
    strength={400}
  >
    <div className='features-container'>
      <Grid fluid>
        <Row>
          <Col xs={12} sm={6} md={4}>
            <FeatureItem imgSrc='/images/features/24-icon.svg' imgAlt='Availability 24hs' title='Availability'>
              Always ready to help you.
            </FeatureItem>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <FeatureItem imgSrc='/images/features/check-icon.svg' imgAlt='trustable' title='Trustable'>
              Your trusted partner in cleaning.
            </FeatureItem>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <FeatureItem
              imgSrc='/images/features/head-icon.svg'
              imgAlt='Customer Satisfaction'
              title='Customer Satisfaction'
            >
              Committed to your complete satisfaction.
            </FeatureItem>
          </Col>
        </Row>
      </Grid>
    </div>
  </Parallax>
)

export default Feature
