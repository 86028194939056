import React from 'react'

import ContactForm from './form'
import ContactDetails from './contactDetails'
import './contact.scss'

const ContactSection = () => (
  <div id='contactUsSection' className='contact-container page-section'>
    <h2>Contact us</h2>
    <p>
      Get in touch with us for any enquire or <a href='/free-quote'>Get a free quote</a>
    </p>
    <ContactForm />
    <ContactDetails />
  </div>
)

export default ContactSection
