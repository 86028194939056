import React from 'react'
import PropTypes from 'prop-types'

import GoogleIcon from '../../../static/images/google-icon.svg'
import StarIcon from '../../../static/images/star-icon.svg'

import './testimonials.scss'

const Testimonials = ({ title, items }) => (
  <div className='page-section testimonials-container'>
    <h2>{title}</h2>
    <div className='testimonial-items'>
      {items.map((item) => (
        <div className='testimonial-box' key={`testimonial-${item.nam}`}>
          <div className='testimonial-header'><b>{item.name}</b> <GoogleIcon className='google-icon' /></div>
          <p>{item.description}</p>
          <div className='stars'>
            <StarIcon className='star-icon' />
            <StarIcon className='star-icon' />
            <StarIcon className='star-icon' />
            <StarIcon className='star-icon' />
            <StarIcon className='star-icon' />
          </div>
        </div>
      ))}
    </div>
  </div>
)

Testimonials.propTypes = {
  title: PropTypes.string,
  items: { name: PropTypes.string, description: PropTypes.string },
}
export default Testimonials
