import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import ServicesSection from '../components/services'
import FeaturesSection from '../components/services/features'
import HeroSection from '../components/hero'
import ContactSection from '../components/contact'
import FreeQuoteBanner from '../components/banner/freeQuoteBanner'
import { commonTags } from '../config/constants'
import Layout from '../layouts'
import SliderSection from '../components/slider/SliderSection'
import Testimonials from '../components/testimonials'

const IndexPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        home: allContentfulPage(filter: { slug: { eq: "/" } }) {
          edges {
            node {
              title
              ogTitle
              ogDescription
              ogKeywords
              ogImage {
                description
                file {
                  url
                  contentType
                  details {
                    size
                    image {
                      width
                      height
                    }
                  }
                }
              }
              heroText
              favIco {
                file {
                  url
                }
              }
              #heroVideo {
              #  file {
              #    url
              #  }
              #}
              items {
                ...on ContentfulHero {
                   internal {
                    type
                  }
                  title
                  image {
                    file{
                      url
                    }
                  }
                  icon {
                    description
                    file {
                      url
                    }
                  }
                }
                ... on ContentfulSliderSection {
                  id
                  internal {
                    type
                  }
                  title
                  small
                  items {
                    fluid(maxHeight: 6280) {
                      sizes
                      src
                      aspectRatio
                      base64
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
                ... on ContentfulClientTestimonials {
                  internal {
                    type
                  }
                  title
                  testimonials {
                    name
                    description
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        ogTitle, ogKeywords, ogDescription, ogImage, favIco, items,
      } = data.home.edges[0].node
      return (
        <Layout location={location}>
          <Helmet
            title={ogTitle}
            meta={commonTags(ogTitle, ogDescription, ogKeywords, ogImage)}
          >
            <link rel='icon' type='image/png' href={favIco.file.url}  />
          </Helmet>
          <HeroSection />
          <ServicesSection />
          {items.map((item) => {
            // The items for the home page are included here
            switch (item.internal.type) {
              // case 'ContentfulVideoSection': return (
              //   <VideoSection
              //     key={item.name}
              //     title={item.title}
              //     youtubeId={item.youtubeId}
              //     video={item.video}
              //     coverImage={item.cover}
              //   />
              // )
              case 'ContentfulSliderSection': {
                return (
                  // used for "Our Clients"
                  <SliderSection
                    key={item.title}
                    title={item.title}
                    small={item.small}
                    items={item.items}
                  />
                )
              }
              case 'ContentfulClientTestimonials': {
                return (
                  <Testimonials title={item.title} items={item.testimonials} />
                )
              }

              default: return <div key='unknown'>.</div>
            }
          })}
          <FreeQuoteBanner />
          <FeaturesSection />
          <ContactSection />
        </Layout>)
    }}
  />
)

// UNUSED SECTION:
//
// ...on ContentfulVideoSection {
//   internal {
//     type
//   }
//   name
//   video {
//     title
//     file {
//       url
//     }
//   }
// }

IndexPage.propTypes = {
  location: PropTypes.object,
}

export default IndexPage
